// @flow
import * as React from 'react';
import s from './PartialRation.scss';
import type { PartialRationObject } from '../store/ration/types';
import ListEntry from './ListEntry';

type Props = {
  partialRation: PartialRationObject,
};

class PartialRation extends React.Component<Props> {
  entries(): Array<React.Node> {
    const { partialRation } = this.props;
    return partialRation.entries.map((entry, idx) => (
      <ListEntry
        style={{ display: 'flex', justifyContent: 'space-between' }}
        key={idx}
        name={entry.feed_type.name}
        value={`${entry.quantity} ${entry.unit}`}
      />
    ));
  }

  render() {
    return <div className={s.wrapper}>{this.entries()}</div>;
  }
}

export default PartialRation;
