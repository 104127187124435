import * as React from "react";
const s = require("./ErrorContainer.scss");

class Error extends React.Component {
    render() {
        return (
            <div className={s.wrapper}>
                <div className={s.content}>
                    <img
                        src="https://s3.eu-west-1.amazonaws.com/prevet-live/web/prevet-logotyp-gron.png"
                        alt="logotype"
                    />
                    <b>Länken gick inte att hitta eller har gått ut.</b>
                </div>
            </div>
        );
    }
}

export default Error;
