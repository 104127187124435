import * as React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import * as userActions from "../store/user/actions";
import { Strings, AppLinks } from "../constants";
const s = require("./ConfirmEmail.scss");

const mapStateToProps = (state: any) => {
  const { emailConfirmed, error } = state.userState;
  return { emailConfirmed, error };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ confirmEmail: userActions.confirmEmail }, dispatch);

type Props = {
  confirmEmail: typeof userActions.confirmEmail;
  emailConfirmed: boolean;
  error?: string;
  match: any;
};
class ConfirmEmail extends React.Component<Props> {
  componentWillMount() {
    const { match, confirmEmail } = this.props;
    confirmEmail(match.params.token);
  }

  render() {
    const { emailConfirmed, error } = this.props;
    return (
      <div className={s.wrapper}>
        <div className={s.textWrapper}>
          {(emailConfirmed || error) && (
            <>
              <p className={s.title}>
                {error ? Strings.emailErrorTitle : Strings.emailConfirmedTitle}
              </p>
              <p className={s.text}>{error || Strings.emailConfirmedBody}</p>
            </>
          )}
        </div>
        <div className={s.image} />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmEmail);
