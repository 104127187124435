import * as React from "react";
import { Switch, BrowserRouter } from "react-router-dom";
import { Route } from "react-router";
import { Provider } from "react-redux";
import { initStore } from "./store";

import AppWrapper from "../src/containers/AppWrapperContainer";
import Wrapper from "../src/containers/WrapperContainer";
import Home from "../src/containers/HomeContainer";
import Animal from "../src/containers/AnimalContainer";
import CardDetailed from "../src/containers/CardDetailedContainer";
import AnimalDetails from "../src/containers/AnimalDetails";
import AnimalContacts from "../src/containers/AnimalContacts";
import ConfirmEmail from "../src/containers/ConfirmEmail";
import Samples from "../src/containers/SamplesContainer";
import Error from "./containers/ErrorContainer";

const store = initStore();

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <AppWrapper>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/error" component={Error} />
              <Route path="/confirm_email/:token" component={ConfirmEmail} />
              <Route
                path="/samples"
                render={props => (
                  <Wrapper {...props}>
                    <Samples />
                  </Wrapper>
                )}
              />
              <Route
                path="/:token"
                render={props => (
                  <Wrapper {...props}>
                    <Switch>
                      <Route path="/:token/cards/:id" component={CardDetailed} />
                      <Route path="/:token/samples" component={Samples} />
                      <Route path="/:token/animals/:id" component={Animal} />
                      <Route path="/:token/details" component={AnimalDetails} />
                      <Route path="/:token/contacts" component={AnimalContacts} />
                    </Switch>
                  </Wrapper>
                )}
              />
              <Route path="*" component={Error} />
            </Switch>
          </AppWrapper>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
