// @flow
import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as animalsActions from "../store/animal/actions";
import * as appActions from "../store/app/actions";
import type { AnimalObject } from "../store/animal/types";
import type { ShareLinkObject } from "../store/app/types";
import s from "./WrapperContainer.scss";

type Props = {
  animal: AnimalObject,
  share_link: ShareLinkObject,
  match: any,
  history: any,
  children: React.Node,
  fetchQuestionMeta: typeof animalsActions.fetchQuestionMeta,
  fetchAnimal: typeof animalsActions.fetchAnimal,
  fetchShareLink: typeof appActions.fetchShareLink
};

const mapStateToProps = state => {
  const { animal } = state.animalState;
  const { share_link } = state.appState;
  return { animal, share_link };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...appActions, ...animalsActions }, dispatch);

class Wrapper extends React.Component<Props> {
  componentDidMount() {
    const { fetchQuestionMeta, fetchShareLink } = this.props;
    const token = this.props.match.params.token;
    fetchQuestionMeta();
    fetchShareLink(token).then(() => this.navigateToEntrypoint());
  }

  navigateToEntrypoint() {
    const token = this.props.match.params.token;
    const { share_link } = this.props;
    
    if (share_link == null) {
      this.props.history.push(`/error`);
      return;
    }

    switch (share_link.link_type) {
      case "card_info":
      case "card":
        this.props.history.push(
          `/${token}/cards/${share_link.subject_hash_id}`
        );
        break;
      case "animal":
        this.props.history.push(
          `/${token}/animals/${share_link.subject_hash_id}`
        );
        break;
      case "animal_info":
        this.props.history.push(
          `/${token}/details/${share_link.subject_hash_id}`
        );
        break;
      case "feces_sample":
        this.props.history.push(`/${token}/samples`);
        break;
      default:
        break;
    }
  }

  render() {
    return <div className={s.wrapper}>{this.props.children}</div>;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapper);
