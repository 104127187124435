// @flow
import * as React from 'react';
import s from './ListEntry.scss';

type Props = {
  name: string,
  checked: boolean,
};

class ListEntryCheckbox extends React.Component<Props> {
  render() {
    const { name, checked } = this.props;

    return (
      <div className={s.listEntry}>
        <span className={s.name}>{name}</span>
        <span className={s.checkbox}>
          {checked && <i className="icon ion-ios-checkmark-empty" />}
        </span>
      </div>
    );
  }
}

export default ListEntryCheckbox;
