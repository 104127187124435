import { Dispatch } from "redux";
import requestManager from "../../libs/requestManager";
import * as t from "./types";

export const fetchShareLink = (token: string) => (dispatch: Dispatch) => {
  dispatch({ type: t.GET_SHARE_LINK_START });
  return requestManager
    .fetchShareLink(token)
    .then((value: any) =>
      dispatch({ type: t.GET_SHARE_LINK_SUCCESS, share_link: value.data })
    )
    .catch((error: any) =>
      dispatch({ type: t.GET_SHARE_LINK_FAIL, error: error.message })
    );
};
