// @flow
import type { UserObject } from '../user/types';

export type ContactObject = {
  id: number,
  title: string,
  name: string,
  phone: string,
  email: string,
};

export type NormalValueObject = {
  temperature: ?string,
  resting_heart_rate: ?string,
  respiratory_rate: ?string,
};

export type AnimalObject = {
  id: ?number,
  name: string,
  medication?: ?string,
  allergies?: ?string,
  notes?: ?string,
  contacts: Array<ContactObject>,
  image_medium?: ?string,
  nickname?: ?string,
  registration_number?: ?string,
  chip_number?: ?string,
  chip_number_placement?: ?string,
  breed?: ?string,
  wither_height?: ?string,
  born?: ?string,
  gender?: ?string,
  color?: ?string,
  sign?: ?string,
  father?: ?string,
  mother?: ?string,
  stable_name?: ?string,
  stable_address?: ?string,
  stable_zipcode?: ?string,
  stable_city?: ?string,
  insurance?: ?string,
  insurance_number?: ?string,
  insurance_type?: ?string,
  normal_values_average: NormalValueObject,
  user: UserObject,
};

export const GET_ANIMAL_SUCCESS = '@@GET_ANIMAL_SUCCESS';
export const GET_ANIMAL_START = '@@GET_ANIMAL_START';
export const GET_ANIMAL_FAIL = '@@GET_ANIMAL_FAIL';
export const GET_QUESTION_META_START = '@@GET_QUESTION_META_START';
export const GET_QUESTION_META_SUCCESS = '@@GET_QUESTION_META_SUCCESS';
export const GET_QUESTION_META_FAIL = '@@GET_QUESTION_META_FAIL';
