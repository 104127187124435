// @flow
import * as React from 'react';
import Header from './Header';
import Button from './Button';
import s from './Filter.scss';
import type { CardType, FilterObject } from '../store/card/types';

type Props = {
  card_types: Array<CardType>,
  filter: FilterObject,
  hideFilter: void => void,
  toggleCardType: void => void,
  resetFilter: void => void,
};

class Filter extends React.Component<Props> {
  render() {
    const {
      card_types,
      filter,
      hideFilter,
      toggleCardType,
      resetFilter,
    } = this.props;
    return (
      <div className={s.filter}>
        <div className={s.filterWrapper}>
          <Header title="Filtrera" handleBack={hideFilter} handleInfo={null} />
          {card_types.map((cardType) => {
            const isActive = filter.card_types.find(activeCardId => parseInt(activeCardId, 10) === cardType.id);
            return (
              <button
                className={`${s.filterButton} ${isActive ? s.active : ''}`}
                id={cardType.id}
                key={cardType.id}
                onClick={toggleCardType}
                style={{ borderLeftColor: `#${cardType.color}` }}
              >
                {cardType.title}
              </button>
            );
          })}
          <div className={s.reset}>
            <Button title="återställ filter" onClick={resetFilter} />
          </div>
        </div>
      </div>
    );
  }
}

export default Filter;
