// @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as animalsActions from "../store/animal/actions";
import * as cardsActions from "../store/card/actions";
import s from "./AnimalContainer.scss";
import Card from "../components/Card";
import Header from "../components/Header";
import Filter from "../components/Filter";
import type { AnimalObject } from "../store/animal/types";
import type { CardObject, CardType, FilterObject } from "../store/card/types";
import type { ShareLinkObject } from "../store/app/types";
import Modal from "../components/Modal";

import moment from "moment";
import { Formatters } from "../constants";
import Error from "./ErrorContainer";

const mapStateToProps = state => {
  const { share_link } = state.appState;
  const { animal, created_at } = state.animalState;
  const { cards, card_types, filter } = state.cardState;
  return {
    animal,
    cards,
    card_types,
    filter,
    created_at,
    share_link
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...cardsActions, ...animalsActions }, dispatch);

const capitalize = (s: ?string) => {
  if (!s) return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

type Props = {
  history: any,
  match: any,
  getCards: typeof cardsActions.getCards,
  toggleCardType: number => void,
  resetFilter: void => void,
  getCardTypes: typeof cardsActions.getCardTypes,
  fetchAnimal: string => void,
  animal: AnimalObject,
  cards: Array<CardObject>,
  card_types: Array<CardType>,
  filter: FilterObject,
  created_at: string,
  share_link: ShareLinkObject
};

type State = {
  showFilter: boolean,
  headerContentBreakpoint: boolean,
  loadMoreCardsBreakpoint: boolean
};

class Animal extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      headerContentBreakpoint: false,
      loadMoreCardsBreakpoint: false,
      showFilter: false
    };

    this.determineScrolled = this.determineScrolled.bind(this);
    this.showFilter = this.showFilter.bind(this);
    this.hideFilter = this.hideFilter.bind(this);
    this.showInfo = this.showInfo.bind(this);
    this.toggleCardType = this.toggleCardType.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
  }

  componentWillMount() {
    const { getCards, getCardTypes, fetchAnimal } = this.props;
    const { token } = this.props.match.params;
    fetchAnimal(token);
    getCards(token);
    getCardTypes();
  }

  componentDidMount() {
    window.addEventListener("scroll", this.determineScrolled);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.determineScrolled);
  }

  determineScrolled: void => void;
  showFilter: void => void;
  hideFilter: void => void;
  showInfo: void => void;
  toggleCardType: void => void;
  resetFilter: void => void;

  determineScrolled() {
    const { getCards } = this.props;
    const body: HTMLElement = ((document.body: any): HTMLElement);
    const html: HTMLElement = ((document.documentElement: any): HTMLElement);
    const documentHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    this.setState(
      {
        loadMoreCardsBreakpoint:
          window.pageYOffset + window.innerHeight > documentHeight - 300
      },
      () => {
        const { loadMoreCardsBreakpoint } = this.state;
        if (loadMoreCardsBreakpoint) {
          getCards();
        }
      }
    );
  }

  showFilter() {
    this.setState({ showFilter: true });
  }

  hideFilter() {
    this.setState({ showFilter: false });
  }

  showInfo() {
    this.props.history.push(`/${this.props.match.params.token}/details`);
  }

  toggleCardType(e) {
    const { toggleCardType } = this.props;
    const { id } = e.target;
    toggleCardType(id);
    this.hideFilter();
  }

  resetFilter() {
    const { resetFilter } = this.props;
    resetFilter();
    this.hideFilter();
  }
  
  isLinkExpired() {
    const createdAt = this.props.created_at;
    
    if (!createdAt)
      return false;
    
    const today = new Date();
    const createdDate = new Date(createdAt);
    const twentyOneDays = 21 * 24 * 60 * 60 * 1000;
    const dif = today - createdDate;
    
    return dif > twentyOneDays;
  }

  authorizedContent() {
    const { animal, cards, card_types, filter, created_at } = this.props;
    const { token } = this.props.match.params;
    const { headerContentBreakpoint, showFilter } = this.state;
    const createdDate = created_at ? new Date(created_at) : new Date();
    return (
      <div className={s.animal}>
        <Header
          title={`Delat ${moment(createdDate).format(Formatters.dMMMYYYY)}`}
          handleInfo={this.showInfo}
          handleBack={null}
        />
        <div className={s.header}>
          <div
            className={`${s.headerContent} ${
              headerContentBreakpoint ? s.hidden : ""
            }`}
          >
            <div className="container">
              <div className="row">
                <div className="col-xs-4">
                  <div
                    className={s.image}
                    style={{
                      backgroundImage: `url(${animal.image_medium || ""})`
                    }}
                  />
                </div>
                <div className="col-xs-8">
                  <span className={s.ownerName}>
                    {capitalize(animal.user.first_name) + " " + capitalize(animal.user.last_name)} 
                  </span>
                  <h1>{capitalize(animal.name)}</h1>
                  <i className={s.nickname}>{capitalize(animal.nickname)}</i>
                  <span className={s.regNr}>
                    <strong>ID</strong> {animal.registration_number}
                  </span>
                </div>
              </div>
            </div>
            <button className={s.showFilter} onClick={this.showFilter}>
              <i className="icon ion-ios-settings-strong" />{" "}
              <span>Filtrera</span>
            </button>
          </div>
        </div>
        <div className={s.feed}>
          {cards
            .sort((a, b) => new Date(b.occurred_at) - new Date(a.occurred_at))
            .map((card, i) => (
              <Card card={card} date={card.occurred_at} token={token} key={i} />
            ))}
        </div>
        {showFilter && (
          <Filter
            key={1}
            card_types={card_types}
            filter={filter}
            hideFilter={this.hideFilter}
            toggleCardType={this.toggleCardType}
            resetFilter={this.resetFilter}
          />
        )}
      </div>
    );
  }

  expiredContent() {
    return <Error />;
  }

  unauthorizedContent() {
    return (
      <div className={s.modalWrapper}>
        <Modal title="OK" ignoresCookies>
          <h2>Ingen åtkomst</h2>
          <h3>Den här delningslänken gäller inte för djur</h3>
        </Modal>
      </div>
    );
  }

  render() {
    const { share_link } = this.props;
    if (this.isLinkExpired()) {
      return this.expiredContent();
    } else {
      return (
        <div>
          {share_link &&
          share_link.link_type === "animal" &&
          this.authorizedContent()}
          {share_link &&
          share_link.link_type !== "animal" &&
          this.unauthorizedContent()}
        </div>
      );
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Animal);
