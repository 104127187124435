// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import s from './ListLink.scss';

type Props = {
  name: string,
  to: string,
};

class ListLink extends React.Component<Props> {
  render() {
    const { name, to } = this.props;
    return (
      <Link className={s.listLink} to={to}>
        <span className={s.name}>{name}</span>
        <i className="icon ion-ios-arrow-right pull-right" />
      </Link>
    );
  }
}

export default ListLink;
