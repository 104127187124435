export const Images = {
  horseEye: require("./assets/horse.jpg")
};

export const Strings = {
  emailConfirmedTitle: "Mejladressen är bekräftad!",
  emailConfirmedBody:
    "Nu kan du logga in med din nya mejladress i appen.\n\nHälsningar\npreVet",
  noSamplesFound: "Inga prover hittades",
  emailErrorTitle: "Kunde inte bekräfta mejladressen",
  preliminary: "Delrapport",
  waiting: "Mottaget träckprov",
  final: "Slutrapport",
  fecesSample: "Träckprov"
};

export const Formatters = {
  dMMMYYYY: "YYYY-MM-DD",
  dMMMYYYYHHMM: "YYYY-MM-DD HH:mm"
};

const AppDeeplink = "prevet://";
export const AppLinks = {
  emailConfirmed: () => `${AppDeeplink}/confirm_email`
};
