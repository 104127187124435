import * as React from "react";
const s = require("./AppWrapperContainer.scss");

class AppWrapper extends React.Component {
  render() {
    return <div className={s.wrapper}>{this.props.children}</div>;
  }
}

export default AppWrapper;
