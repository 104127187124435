// @flow
import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Header from "../components/Header";
import ListEntry from "../components/ListEntry";
import ListTitle from "../components/ListTitle";
import ListLink from "../components/ListLink";
import * as animalsActions from "../store/animal/actions";
import * as appActions from "../store/app/actions";
import type { AnimalObject } from "../store/animal/types";
import type { ShareLinkObject } from "../store/app/types";
import s from "./AnimalDetails.scss";

type Props = {
  history: any,
  match: any,
  fetchAnimal: typeof animalsActions.fetchAnimal,
  fetchShareLink: typeof appActions.fetchShareLink,
  animal: AnimalObject,
  share_link: ShareLinkObject
};

const mapStateToProps = state => {
  const { animal } = state.animalState;
  const { share_link } = state.appState;
  return { animal, share_link };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...animalsActions }, dispatch);

class AnimalDetails extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.handleBack = this.handleBack.bind(this);
  }

  handleBack: void => void;

  handleBack() {
    this.props.history.goBack();
  }

  componentWillMount() {
    const { fetchAnimal } = this.props;
    const token = this.props.match.params.token;
    fetchAnimal(token);
  }

  localizedChipPlacement(location: ?string): string {
    switch (location) {
      case "left":
        return "Vänster";
      case "right":
        return "Höger";
      default:
        return "";
    }
  }

  render() {
    const { animal, share_link } = this.props;
    
    return (
      <div className={s.animalDetails}>
        <Header
          handleBack={
            share_link && share_link.link_type === "animal_info"
              ? null
              : this.handleBack
          }
          title={this.props.animal.name}
          handleInfo={null}
        />
        <ListTitle title="Ägaruppgifter" />
        <ListEntry
          name="Namn"
          value={`${animal.user.first_name || ""} ${animal.user.last_name ||
            ""}`}
        />
        <ListEntry name="Email" value={animal.user.email} />
        <ListEntry name="Telefon" value={animal.user.phone} />
        <ListEntry name="Adress" value={animal.user.address || ""} />
        <ListEntry name="Postnummer" value={animal.user.zipcode || ""} />
        <ListEntry name="Stad" value={animal.user.city || ""} />
        <ListEntry
          name="Dokumenterade meriter"
          value={
            <span>
              <br />
              {animal.user.merits}
            </span>
          }
        />
        <ListEntry
          name="Egen presentation"
          value={
            <span>
              <br />
              {animal.user.other_merits}
            </span>
          }
        />

        <ListTitle title="Normalvärden" />
        <ListEntry
          name="Normaltemperatur"
          value={`${animal.normal_values_average.temperature || "-"} C`}
        />
        <ListEntry
          name="Vilopuls"
          value={`${animal.normal_values_average.resting_heart_rate || "-"}BPM`}
        />
        <ListEntry
          name="Andningsfrekvens"
          value={`${animal.normal_values_average.respiratory_rate || "-"} Hz`}
        />

        <ListTitle title="Hästuppgifter" />
        <ListEntry name="Namn" value={animal.name} />
        <ListEntry name="Smeknamn" value={animal.nickname} />
        <ListEntry
          name="Registreringsnummer"
          value={animal.registration_number}
        />
        <ListEntry name="Chipnummer" value={animal.chip_number} />
        <ListEntry
          name="Chipnumrets placering"
          value={this.localizedChipPlacement(animal.chip_number_placement)}
        />
        <ListEntry name="Ras" value={animal.breed} />
        <ListEntry name="Mankhöjd" value={animal.wither_height} />
        <ListEntry name="Födelsedatum" value={animal.born} />
        <ListEntry name="Kön" value={animal.gender} />
        <ListEntry name="Färg" value={animal.color} />
        <ListEntry name="Tecken" value={animal.sign} />
        <ListEntry name="Far" value={animal.father} />
        <ListEntry name="Mor" value={animal.mother} />
        <ListLink name="Kontaktpersoner" to={share_link === undefined ? "" : "/" + share_link.token + "/contacts"} value={null} />

        <ListTitle title="Stall" />
        <ListEntry name="Stallnamn" value={animal.stable_name} />
        <ListEntry name="Adress" value={animal.stable_address} />
        <ListEntry name="Postnummer" value={animal.stable_zipcode} />
        <ListEntry name="Ort" value={animal.stable_city} />

        <ListTitle title="Försäkring" />
        <ListEntry name="Försäkringsbolag" value={animal.insurance} />
        <ListEntry name="Försäkringsnummer" value={animal.insurance_number} />
        <ListEntry name="Försäkringsform" value={animal.insurance_type} />

        <ListTitle title="Övrigt" />
        <ListEntry
          name="Medicinering"
          value={
            <span>
              <br />
              {animal.medication}
            </span>
          }
        />
        <ListEntry
          name="Allergier"
          value={
            <span>
              <br />
              {animal.allergies}
            </span>
          }
        />
        <ListEntry
          name="Övriga uppgifter"
          value={
            <span>
              <br />
              {animal.notes}
            </span>
          }
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnimalDetails);
