// @flow
import * as React from 'react';
import s from './Media.scss';
import type { MediaObject } from '../store/media/types';
import type {DocumentObject} from "../store/document/types";

type MediaSingleProps = {
  media: MediaObject,
  width: number,
};

type MediaSingleState = {
  isPlaying: boolean,
};

class MediaSingle extends React.Component<MediaSingleProps, MediaSingleState> {
  constructor(props) {
    super(props);
    this.togglePlayVideo = this.togglePlayVideo.bind(this);
    this.state = {
      isPlaying: false,
    };
  }

  togglePlayVideo: void => void;
  video: ?HTMLVideoElement;

  togglePlayVideo() {
    const { isPlaying } = this.state;
    if (this.video !== undefined) {
      const video: HTMLVideoElement = ((this.video: any): HTMLVideoElement);
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
    }
    this.setState({ isPlaying: !isPlaying });
  }

  render() {
    const { media, width } = this.props;
    const { isPlaying } = this.state;
    if (media.image && media.image.slice(0, 4) === 'http') {
      return (
        <div
          className={s.slide}
          style={{ width: `${width}%`, paddingBottom: `${width}%` }}
        >
          <div
            className={s.image}
            style={{ backgroundImage: `url(${media.image_medium})` }}
          />
        </div>
      );
    }
    return (
      <div
        className={s.slide}
        style={{ width: `${width}%`, paddingBottom: `${width}%` }}
        onClick={this.togglePlayVideo}
      >
        <video
          loop
          src={media.movie}
          ref={(r) => {
            this.video = r;
          }}
        />
        {!isPlaying && <i className={`icon ion-ios-play ${s.play}`} />}
      </div>
    );
  }
}

const MediaThumb = ({ media, onClick, index }) => {
  const imageUrl =
    media.image && media.image.slice(0, 4) === 'http'
      ? media.image_medium
      : media.movie_thumb;
  return (
    <div
      className={s.thumbSlide}
      onClick={onClick}
      data-index={index}
      style={{ backgroundImage: `url(${imageUrl})` }}
    />
  );
};

const DocumentThumb = ({ doc, index }) => {
  const fileName = doc.category + ".pdf";
  return (
      <a href={doc.document_url} download={fileName} target="_blank" rel="noopener noreferrer">
        <div
          className={s.documentWithTooltip}
          data-index={index}
        >
          <span className={s.tooltipText}>{fileName}</span>
        </div>
      </a>
  );
};

type MediaProps = {
  media: Array<MediaObject>,
  doc: Array<DocumentObject>
};

type MediaState = {
  currentImage: number,
};

class Media extends React.Component<MediaProps, MediaState> {
  constructor(props: MediaProps) {
    super(props);
    this.state = {
      currentImage: 0,
    };
    this.handleLeft = this.handleLeft.bind(this);
    this.handleRight = this.handleRight.bind(this);
    this.handleDotClick = this.handleDotClick.bind(this);
  }

  handleLeft: void => void;
  handleRight: void => void;
  handleDotClick: MouseEvent => void;

  handleRight() {
    const { currentImage } = this.state;
    const { media } = this.props;
    if (currentImage === media.length - 1) return;
    this.setState({ currentImage: currentImage + 1 });
  }

  handleLeft() {
    const { currentImage } = this.state;
    if (currentImage === 0) return;
    this.setState({ currentImage: currentImage - 1 });
  }

  handleDotClick(e: MouseEvent) {
    this.setState({
      currentImage: parseInt(
        (e.currentTarget: any).getAttribute('data-index'),
        10,
      ),
    });
  }

  render() {
    const { media, doc } = this.props;
    const { currentImage } = this.state;
    return (
      <div className={s.wrapper}>
        <div className={s.slider}>
          <div
            className={s.slides}
            style={{
              left: `-${currentImage * 100}%`,
              width: `${media.length * 100}%`,
            }}
          >
            {media.map(item => (
              <MediaSingle
                key={item.id}
                media={item}
                width={100 / media.length}
              />
            ))}
          </div>
          <div className={s.dots}>
            {media.map((item, i) => (
              <button
                key={i}
                data-index={i}
                onClick={this.handleDotClick}
                className={`${s.dot} ${i === currentImage ? s.active : ''}`}
              />
            ))}
          </div>
          <button className={s.left} onClick={this.handleLeft}>
            <i className="icon ion-chevron-left" />
          </button>
          <button className={s.right} onClick={this.handleRight}>
            <i className="icon ion-chevron-right" />
          </button>
        </div>
        <div className={s.thumbs}>
          {media.map((item, i) => (
            <MediaThumb
              key={item.id}
              media={item}
              index={i}
              onClick={this.handleDotClick}
            />
          ))}
          {doc.map((item, i) => (
            <DocumentThumb
              key={item.id}
              doc={item}
              index={i}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default Media;
