// @flow
import * as React from 'react';

import Button from './Button';
import s from './Modal.scss';

type Props = {
  title: string,
  children: ?React.Node,
  ignoresCookies: boolean,
};
type State = {
  isOpen: boolean,
};

class Modal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.close = this.close.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      isOpen:
        props.ignoresCookies ||
        document.cookie.indexOf('hideModal=true') === -1,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  wrapperRef: ?HTMLDivElement;
  close: void => void;
  handleClickOutside: MouseEvent => void;

  handleClickOutside(event: MouseEvent) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains((event.currentTarget: any))
    ) {
      this.close();
    }
  }

  close() {
    this.setState({ isOpen: false });
    if (!this.props.ignoresCookies) {
      document.cookie = 'hideModal=true';
    }
  }

  open() {
    this.setState({ isOpen: true });
  }

  render() {
    const { children, title } = this.props;
    const { isOpen } = this.state;

    if (!isOpen) return false;

    return (
      <div className={s.wrapper}>
        <div
          className={s.content}
          ref={(r) => {
            this.wrapperRef = r;
          }}
        >
          {children}
          <div className={s.footer}>
            <Button onClick={this.close} title={title} />
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
