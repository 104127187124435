// @flow
export type ShareLinkType = 'animal' | 'card' | 'animal_info';
export type ShareLinkObject = {
  id: number,
  token: string,
  subject_hash_id: string,
  link_type: ShareLinkType,
};

export const GET_SHARE_LINK_START = '@@GET_SHARE_LINK_START';
export const GET_SHARE_LINK_FAIL = '@@GET_SHARE_LINK_FAIL';
export const GET_SHARE_LINK_SUCCESS = '@@GET_SHARE_LINK_SUCCESS';
