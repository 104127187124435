// @flow
import * as t from './types';
import * as a from './actions';

export type AppState = {
  share_link: ?t.ShareLinkObject,
};

export const initialState: AppState = {
  share_link: undefined,
};

export const appReducer = (
  state: AppState = initialState,
  action: a.ThunkAction,
) => {
  switch (action.type) {
    case t.GET_SHARE_LINK_SUCCESS:
      return {
        ...state,
        share_link: action.share_link,
      };
    default:
      return { ...state };
  }
};
