// @flow
import * as React from 'react';
import s from './RationTab.scss';

type Props = {
  title: string,
  isSelected: boolean,
  isSummary: boolean,
  isEnabled: boolean,
  onClick: void => void,
  style?: {
    marginRight: string,
  },
};

class RationTab extends React.Component<Props> {
  render() {
    const {
      title,
      isSelected,
      onClick,
      isSummary,
      isEnabled,
      style,
    } = this.props;
    const inactiveClass = !isSelected && isEnabled ? s.inactive : '';
    return (
      <div
        style={style}
        className={`${s.wrapper} ${isSelected ? s.active : inactiveClass} ${
          isSummary ? s.total : ''
        } ${!isEnabled ? s.disabled : ''}`}
      >
        <button onClick={isEnabled ? onClick : null}> {title} </button>
      </div>
    );
  }
}

export default RationTab;
