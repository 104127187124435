import * as React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import * as userActions from "../store/user/actions";
import Report from "../components/Report";
import Header from "../components/Header";
import { Strings } from "../constants";
const s = require("./SamplesContainer.scss");

const MOCKED = false;

const mapStateToProps = (state: any) => {
  const { sample } = state.userState;
  return { sample };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ fetchSample: userActions.fetchSample }, dispatch);

type Props = {
  fetchSample: typeof userActions.fetchSample;
  sample: any;
  match: any;
};

class SamplesContainer extends React.Component<Props> {
  componentWillMount = async () => {
    const {
      fetchSample,
      match: {
        params: { token }
      }
    } = this.props;
    await fetchSample(token, MOCKED);
  };

  render() {
    const { sample } = this.props;
    if (!sample) return <div />;
    return (
      <div className={s.wrapper}>
        <Header title={Strings.fecesSample} />
        <div
          className={s.info}
          dangerouslySetInnerHTML={{ __html: sample.info }}
        />
        <p className={s.title}>{sample.horse}</p>
        <div className={s.delimiter} />
        <Report sample={sample} />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SamplesContainer);
