// @flow
import type { RationObject } from '../ration/types';
import type { MediaObject } from '../media/types';
import type {DocumentObject} from "../document/types";

export type CardType = {
  id: number,
  color: string,
  title: string,
  type_special: string,
};

export type FilterObject = {
  card_types: Array<CardType>,
};

export type CardActivity = {
  id: number,
  name: string,
};

export type AnswerMetaObject = {
  id: number,
  answer: string,
};

export type QuestionMetaObject = {
  id: number,
  question_type: string,
  is_multiple: boolean,
  question: string,
  answer_metas: Array<AnswerMetaObject>,
};

export type CategoryObject = {
  id: number,
  title: string,
};

export type AnswerObject = {
  id: number,
  question_meta_id: number,
  answer_meta_id: number,
  answer: string,
  is_active: boolean,
};

export type CardObject = {
  id: number,
  hash_id: ?string,
  description: ?string,
  card_type: CardType,
  occurred_at: string,
  created_at: string,
  media: Array<MediaObject>,
  document: Array<DocumentObject>,
  answers: Array<AnswerObject>,
  reminder: any,
  reminder_date: string,
  reminder_recurrent_count: number,
  reminder_interval_unit: number,
  reminder_interval_unit: string,
  exterior_estimation: string,
  emergency_temperature: number,
  emergency_resting_heart_rate: number,
  emergency_respiratory_rate: number,
  horse_training_satisfaction: number,
  rider_training_satisfaction: number,
  training_intensity: number,
  training_time: number,
  card_activities: Array<CardActivity>,
  ration: ?RationObject,
};

export const GET_CARD_TYPES_START = '@@GET_CARD_TYPES_START';
export const GET_CARD_TYPES_SUCCESS = '@@GET_CARD_TYPES_SUCCESS';
export const GET_CARD_TYPES_FAILED = '@@GET_CARD_TYPES_FAILED';
export const VIEW_CARD_START = '@@VIEW_CARD_START';
export const VIEW_CARD_SUCCESS = '@@VIEW_CARD_SUCCESS';
export const VIEW_CARD_FAILED = '@@VIEW_CARD_FAILED';
export const GET_CARDS_START = '@@GET_CARDS_START';
export const GET_CARDS_SUCCESS = '@@GET_CARDS_SUCCESS';
export const GET_CARDS_FAILED = '@@GET_CARDS_FAILED';
export const TOGGLE_CARD_TYPE = '@@TOGGLE_CARD_TYPE';
export const SET_CARD_TYPE = '@@SET_CARD_TYPE';
export const REMOVE_CARD_TYPE = '@@REMOVE_CARD_TYPE';
export const RESET_FILTER = '@@RESET_FILTER';
