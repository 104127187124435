import * as React from "react";
const s = require("./HomeContainer.scss");

class Home extends React.Component {
  render() {
    return (
      <div className={s.wrapper}>
        <div className={s.content}>
          <img
            src="https://s3.eu-west-1.amazonaws.com/prevet-live/web/prevet-logotyp-gron.png"
            alt="logotype"
          />
          <p className={s.title}>Kontakt för mer information:</p>
          <p>
            <strong>Jenny Gagnér CEO preVet AB</strong>
          </p>
          <p>
            <a href="mailto: jenny.gagner@prevet.se">jenny.gagner@prevet.se</a>
          </p>
          <p>
            <a href="tel: +46735122857">+4673 512 28 57</a>
          </p>
        </div>
      </div>
    );
  }
}

export default Home;
