// @flow
import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Header from "../components/Header";
import ListEntry from "../components/ListEntry";
import ListTitle from "../components/ListTitle";
import * as animalsActions from "../store/animal/actions";
import type { AnimalObject } from "../store/animal/types";

import s from "./AnimalContacts.scss";

type Props = {
  animal: AnimalObject,
  fetchAnimal: (token: ?string) => void,
  match: any,
  history: any
};

const mapStateToProps = state => {
  const { animal } = state.animalState;
  return { animal };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...animalsActions }, dispatch);

class AnimalDetails extends React.Component<Props> {
  handleBack: void => void;

  constructor(props: Props) {
    super(props);
    this.handleBack = this.handleBack.bind(this);
  }

  handleBack() {
    this.props.history.goBack();
  }

  componentWillMount() {
    const { fetchAnimal } = this.props;
    const token = this.props.match.params.token;
    fetchAnimal(token);
  }

  render() {
    const { animal } = this.props;

    return (
      <div className={s.animalDetails}>
        <Header
          handleBack={this.handleBack}
          title="Kontaktpersoner"
          handleInfo={null}
        />

        {animal.contacts.map(contact => (
          <div key={contact.id}>
            <ListTitle title={contact.title} />
            <ListEntry name="Namn" value={contact.name} />
            <ListEntry name="Telefon" value={contact.phone} />
            <ListEntry name="Epost" value={contact.email} />
          </div>
        ))}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnimalDetails);
