import * as React from 'react';
import s from './ListEntry.scss';

type Props = {
  name: string,
  value: string,
  style?: {
    display: string,
    justifyContent: string,
  },
};

class ListEntry extends React.Component<Props> {
  render() {
    const { name, value, style } = this.props;

    return (
      <div className={s.listEntry} style={style}>
        <span className={s.name}>{name}</span>
        <span className={s.value}>{value}</span>
      </div>
    );
  }
}

export default ListEntry;
