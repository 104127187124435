import request from "axios";

const API_URL = "/v1";

export default {
  fetchAnimal(token) {
    return request({
      method: "GET",
      url: `${API_URL}/animals/view/${token}`,
      responseType: "json"
    });
  },
  fetchCard(token, id) {
    return request({
      method: "GET",
      url: `${API_URL}/animals/view/${token}/cards/${id}`,
      responseType: "json"
    });
  },
  fetchCards(token, page, cardTypes) {
    return request({
      method: "GET",
      url: `${API_URL}/animals/view/${token}/cards`,
      params: {
        page,
        card_type_ids: cardTypes
      },
      responseType: "json"
    });
  },
  getCardTypes() {
    return request({
      method: "GET",
      url: `${API_URL}/card_types`,
      responseType: "json"
    });
  },
  fetchQuestionMeta() {
    return request({
      method: "GET",
      url: `${API_URL}/question_meta`,
      responseType: "json"
    });
  },
  fetchInstructionMedia() {
    return request({
      method: "GET",
      url: `${API_URL}/categories/media`,
      responseType: "json"
    });
  },
  updateInstructionMedia(data) {
    return request({
      method: "PUT",
      data: { categories: data },
      url: `${API_URL}/media/instructions`,
      responseType: "json"
    });
  },
  fetchShareLink(token) {
    return request({
      method: "GET",
      url: `${API_URL}/share_links/${token}`,
      responseType: "json"
    });
  },
  fetchSample(token, mocked) {
    return request({
      method: "GET",
      url: mocked
        ? `${API_URL}/samples/view/${token}/mocked`
        : `${API_URL}/samples/view/${token}`,
      responseType: "json"
    });
  },
  confirmEmail(token) {
    return request({
      method: "GET",
      url: `${API_URL}/users/confirm_email`,
      params: { token }
    });
  }
};
