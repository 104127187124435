// @flow
import * as React from 'react';
import s from './Button.scss';

type Props = {
  onClick: void => void,
  title: string,
};

const Button = ({ onClick, title }: Props) => (
  <button className={s.button} onClick={onClick}>
    {title}
  </button>
);

export default Button;
