import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import reducers, { initialState } from "./store/index";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const reducer = combineReducers(reducers);

export const initStore = (state = initialState) =>
  createStore(
    reducer,
    state,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
