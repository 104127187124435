import * as t from "./types";

export const initialState = {
  sample: undefined,
  emailConfirmed: undefined,
  error: undefined
};

export const userReducer = (state, action) => {
  switch (action.type) {
    case t.GET_SAMPLES_SUCCESS:
      return {
        ...state,
        sample: action.data.sample
      };
    case t.CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        emailConfirmed: true
      };
    case t.CONFIRM_EMAIL_FAIL:
      return {
        ...state,
        error: action.error
      };
    default:
      return { ...state };
  }
};
