// @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../components/Header";
import Media from "../components/Media";
import ListTitle from "../components/ListTitle";
import RatingListEntry from "../components/RatingListEntry";
import SliderListEntry from "../components/SliderListEntry";
import ListEntry from "../components/ListEntry";
import ListEntryCheckbox from "../components/ListEntryCheckbox";
import { CardObject, QuestionMetaObject } from "../store/card/types";
import * as cardActions from "../store/card/actions";
import { AnimalObject } from "../store/animal/types";
import Ration from "../components/Ration";
import { ShareLinkObject } from "../store/app/types";

import moment from "moment";
import { Formatters } from "../constants";
import Error from "./ErrorContainer";

const s = require("./CardDetailedContainer.scss");

type Props = {
  animal: AnimalObject,
  cards: Array<CardObject>,
  questionMeta: Array<QuestionMetaObject>,
  getCard: typeof cardActions.getCard,
  match: any,
  history: any,
  share_link: ShareLinkObject
};

const mapStateToProps = state => {
  const { share_link } = state.appState;
  const { cards } = state.cardState;
  const { questionMeta, animal } = state.animalState;
  return {
    cards,
    questionMeta,
    animal,
    share_link
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(cardActions, dispatch);

class CardDetailed extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.handleBack = this.handleBack.bind(this);
    this.showInfo = this.showInfo.bind(this);
  }


  componentWillMount() {
    const { getCard } = this.props;
    const id = this.props.match.params.id;
    const token = this.props.match.params.token;
    getCard(token, id);
  }

  handleBack() {
    this.props.history.goBack();
  }

  prettifyUnit(unit: string): string {
    switch (unit) {
      case "day":
        return "d";
      case "week":
        return "v";
      case "month":
        return "m";
      case "year":
        return "år";
      default:
        return "";
    }
  }

  showInfo() {
    this.props.history.push(`/${this.props.match.params.token}/details`);
  }

  questionsAndAnswers = (types: Array<string>) => {
    const { questionMeta } = this.props;
    const { id } = this.props.match.params;
    const card = this.props.cards.find(
      card => card.id === parseInt(id) || card.hash_id === id
    );
    return questionMeta
      .filter(qm => types.includes(qm.question_type))
      .sort((x, y) => {
        return (x.is_multiple === y.is_multiple)? 0 : x.is_multiple ? 1 : -1;
      })
      .map(q =>
        q.is_multiple
          ? this.multiSelectQuestionAndAnswersTitle(q, card)
          : this.singleSelectQuestionAndAnswers(q, card)
      );
  };

  multiSelectQuestionAndAnswersTitle = (q, card) => {
    return (
      <div key={q.id}>
        <ListTitle title={q.question} />
        <div>
          { this.multiSelectQuestionAndAnswers(q, card) }
        </div>
      </div>
    );
  };

  multiSelectQuestionAndAnswers = (q, card) => (
    q.answer_metas.map(a => {
      const checked =
        card && card.answers
          ? card.answers
          .filter(a => a.is_active)
          .find(ans => ans.answer_meta_id === a.id) !== undefined
          : false;
      if (checked) {
        return (<ListEntryCheckbox key={a.id} name={a.answer} checked={checked} />);
      }
      return null;
    })
  );

  singleSelectQuestionAndAnswers = (q, card) => {
    let answer = "";
    const answers = card ? card.answers : [];
    answers
      .filter(a => a.is_active)
      .forEach(a => {
        if (q.id === a.question_meta_id) answer += `, ${a.answer}`;
      });
    answer = answer.slice(2);
    return answer.length > 0 ? <ListEntry key={q.id} name={q.question} value={answer} /> : null;
  };

  isLinkExpired(createdAt) {
    if (!createdAt)
      return false;

    const today = new Date();
    const createdDate = new Date(createdAt);
    const twentyOneDays = 21 * 24 * 60 * 60 * 1000;
    const dif = today.getTime() - createdDate.getTime();

    return dif > twentyOneDays;
  }

  render() {
    const { questionMeta, animal, share_link } = this.props;
    const { id } = this.props.match.params;
    const card = this.props.cards.find(
      card => card.id === parseInt(id) || card.hash_id === id
    );
    
    if (!card)
      return <Error />;

    const hasMedia = card.media.length > 0 || card.document.length > 0;
    const sortedMeta = {};
    questionMeta.forEach(item => {
      if (sortedMeta[item.question_type] === undefined) {
        sortedMeta[item.question_type] = [];
      }
      sortedMeta[item.question_type].push(item);
    });

    const { card_type } = card;
    return (
      <div>
        <div
          className={s.border}
          style={{ backgroundColor: `#${card_type.color}` }}
        />
        {share_link && (
          <Header
            handleBack={
              share_link.link_type !== "animal" ? null : this.handleBack
            }
            title={card_type.title}
            handleInfo={
              share_link.link_type === "card_info" && this.showInfo
            }
          />
        )}
        {hasMedia && <Media media={card.media} doc={card.document} />}
        {card.reminder && (
          <div className={s.reminder}>
            <div className="container">
              <div className="row">
                <div className="col-xs-1">
                  <i className="icon ion-ios-calendar-outline" />
                </div>
                <div className="col-xs-8">
                  {moment(card.reminder_date).format(Formatters.dMMMYYYYHHMM)}
                </div>
                <div className="col-xs-3 text-right">
                  <i className={`${s.reminderIcon} icon ion-ios-loop-strong`} />{" "}
                  {card.reminder_recurrent_count}
                  {this.prettifyUnit(card.reminder_interval_unit)}
                </div>
              </div>
            </div>
          </div>
        )}
        {card.description && (
          <div className={s.description}>
            <div className="container">
              <div className="row">
                <div className="col-xs-1">
                  <i className="icon ion-ios-compose-outline" />
                </div>
                <div className="col-xs-11">{card.description}</div>
              </div>
            </div>
          </div>
        )}
        {card.created_at && (
          <ListEntry
            name="Datum"
            value={moment(card.created_at).format(Formatters.dMMMYYYYHHMM)}
          />
        )}
        {card.card_type.type_special === "ration" && card.ration != null && (
          <Ration title={`${animal.name}s foderstat`} ration={card.ration} />
        )}
        {card.card_type.type_special === "exterior" && (
          <div>
            <ListTitle title="Hullbedömning" />
            <ListEntry name="Värde" value={card.exterior_estimation} />
          </div>
        )}
        {card.card_type.type_special === "training" && (
          <div>
            <ListEntry
              name="Aktivitet"
              value={
                card.card_activities != undefined && card.card_activities.length > 0
                  ? card.card_activities[0].name
                  : "Ingen aktivitet vald"
              }
            />
            <RatingListEntry
              name="Hästprestation"
              value={card.horse_training_satisfaction}
            />
            <RatingListEntry
              name="Tränarprestation"
              value={card.rider_training_satisfaction}
            />
            <RatingListEntry
              name="Intensitet"
              value={card.training_intensity}
            />
            <SliderListEntry
              name="Minuter"
              value={card.training_time}
              formattedValue={`${card.training_time || 0}`}
            />
          </div>
        )}
        {card.card_type.type_special === "health" && (
          <div>
            <ListEntry name="Djur" value={animal.name || ""} />
            {this.questionsAndAnswers(["health"])}
          </div>
        )}
        {card.card_type.type_special === "health_status" && (
          <div>
            <ListEntry
                name="Temperatur"
                value={`${card.temperature || "0"}°C`}
            />
            <ListEntry
                name="Puls"
                value={`${card.resting_heart_rate || "0"}BPM`}
            />
            <ListEntry
                name="Andningsfrekvens"
                value={`${card.respiratory_rate || "0"}Hz`}
            />
          </div>
        )}
        {card.card_type.type_special === "emergency" && (
          <div>
            <div>
              <ListTitle title="Allmänt" />
              <ListEntry
                name="Skadan inträffade"
                value={`${moment(card.occurred_at).format(
                  Formatters.dMMMYYYYHHMM
                ) || ""}`}
              />
              <ListEntry
                name="Temperatur"
                value={`${card.emergency_temperature || "0"}°C / ${animal
                  .normal_values_average.temperature || "-"}°C`}
              />
              <ListEntry
                name="Puls"
                value={`${card.emergency_resting_heart_rate ||
                  "0"}BPM / ${animal.normal_values_average.resting_heart_rate ||
                  "-"}BPM`}
              />
              <ListEntry
                name="Andningsfrekvens"
                value={`${card.emergency_respiratory_rate || "0"}Hz / 
                ${animal.normal_values_average.respiratory_rate || "-"}Hz`}
              />
            </div>
            <div className={s.qa}>
              {this.questionsAndAnswers(["condition", "symptoms", "area"])}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardDetailed);

// {Object.keys(sortedMeta).map((sm, i) => (
//   <div key={i}>
//     {
//       sortedMeta[sm][0].is_multiple ?
//         sortedMeta[sm].map(q => this.questionsAndAnswers(q.question_type)) :
//         <div>
//           <ListTitle title="Allmäntillstånd" />
//           {sortedMeta[sm].map(q => this.questionsAndAnswers(q.question_type))}
//           )}
//         </div>
//     }
//   </div>
// ))
