// @flow
import * as React from "react";
import s from "./Header.scss";

const capitalize = (s: string) => {
  if (!s) return;
  return s.charAt(0).toUpperCase() + s.slice(1);
};

type Props = {
  handleBack: ?(void) => void,
  handleInfo: ?(void) => void,
  title: string
};

class Header extends React.Component<Props> {
  render() {
    const { handleBack, handleInfo, title } = this.props;

    return (
      <div className={s.wrapper}>
        <div className={s.header}>
          <div className="container">
            <div className="row">
              <div className="col-xs-3">
                {handleBack && (
                  <button onClick={handleBack}>
                    <i className="icon ion-ios-arrow-left" />
                  </button>
                )}
              </div>
              <div className="col-xs-6">
                <span className={s.title}>{capitalize(title)}</span>
              </div>
              <div className="col-xs-3 text-right">
                {handleInfo && (
                  <button className={s.details} onClick={handleInfo}>
                    Detaljer
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
