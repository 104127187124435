// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import s from "./Card.scss";
import type { CardObject } from "../store/card/types";
import moment from "moment";
import { Formatters } from "../constants";

type Props = {
  token: string,
  date: string,
  card: CardObject
};

class Card extends React.Component<Props> {
  prettifyUnit(unit: string) {
    switch (unit) {
      case "day":
        return "d";
      case "week":
        return "v";
      case "month":
        return "m";
      case "year":
        return "år";
      default:
        return "";
    }
  }
  render() {
    const { token, date } = this.props;
    const { card_type = {}, description, id } = this.props.card;
    const { color = "", title = "" } = card_type || {};
    const prettyDate = new Date(date);
    const to = `/${token}/cards/${id}`;
    return (
      <Link to={to}>
        <div className={s.card}>
          <div className={s.timeline}>
            <div
              className={s.bullet}
              style={{ backgroundColor: `#${color}` }}
            />
            <div
              className={s.bulletBorder}
              style={{ backgroundColor: `#${color}` }}
            />
          </div>
          <div>
            <div className={s.header}>
              <h2 className={s.cardTitle}>{title}</h2>
              <span className={s.date}>
                {moment(prettyDate).format(Formatters.dMMMYYYYHHMM)}
              </span>
            </div>
            <div className={s.content}>{description}</div>
          </div>
        </div>
      </Link>
    );
  }
}

export default Card;
