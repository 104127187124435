import * as React from "react";
import moment from "moment";
import { Formatters, Strings } from "../constants";
const s = require("./Report.scss");

type Props = {
  sample: any;
};

class Report extends React.Component<Props> {
  private renderResult = (row: any, key: number) => (
    <div key={key} className={s.resultRow}>
      <p className={s.resultText}>{row.name}</p>
      <p className={s.resultText}>{row.result}</p>
    </div>
  );

  private reportTitle = () => {
    switch (this.props.sample.status) {
      case "preliminary":
        return Strings.preliminary;
      case "waiting":
        return Strings.waiting;
      case "final":
        return Strings.final;
    }
  };

  render() {
    const { sample } = this.props;
    return (
      <div className={s.wrapper}>
        <p className={s.title}>{this.reportTitle()}</p>
        <p className={s.date}>
          {moment(sample.analysis_date).format(Formatters.dMMMYYYY)}
        </p>
        {sample.details && (
          <>
            <div className={s.columnHeaders}>
              <p>Analys</p>
              <p>Resultat</p>
            </div>
            <div className={s.resultList}>
              {sample.details.map((r: any, key: number) =>
                this.renderResult(r, key)
              )}
            </div>
          </>
        )}
        {sample.message && (
          <>
            <p className={s.messageTitle}>Meddelande från laboratoriet</p>
            <p className={s.message}>{sample.message}</p>
          </>
        )}
      </div>
    );
  }
}

export default Report;
