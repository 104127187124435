// @flow
import * as React from 'react';
import s from './SliderListEntry.scss';

type Props = {
  name: string,
  value: number,
  formattedValue: string,
};

class SliderListEntry extends React.Component<Props> {
  render() {
    const { name, value, formattedValue } = this.props;
    return (
      <div className={s.listEntry}>
        <span className={s.name}>{name}</span>
        <div className={s.sliderTrack}>
          <div
            className={s.activeSliderTrack}
            style={{ width: `${value || 0}%` }}
          />
        </div>
        <span className={s.value}>{formattedValue}</span>
      </div>
    );
  }
}

export default SliderListEntry;
