// @flow
import requestManager from "../../libs/requestManager";
import * as t from "./types";

type GetAnimalAction = { type: typeof t.GET_ANIMAL_START };
type GetAnimalFailedAction = { type: typeof t.GET_ANIMAL_FAIL, error: ?string };
type GetAnimalSuccessAction = {
  type: typeof t.GET_ANIMAL_SUCCESS,
  animal: t.AnimalObject
};
type GetQuestionMetaAction = { type: typeof t.GET_QUESTION_META_START };
type GetQuestionMetaFailedAction = {
  type: typeof t.GET_QUESTION_META_FAIL,
  error: ?string
};
type GetQuestionMetaSuccessAction = {
  type: typeof t.GET_QUESTION_META_SUCCESS,
  animal: t.AnimalObject
};

type Action =
  | GetAnimalAction
  | GetAnimalFailedAction
  | GetAnimalSuccessAction
  | GetQuestionMetaAction
  | GetQuestionMetaFailedAction
  | GetQuestionMetaSuccessAction;

export type GetState = () => Object;
export type PromiseAction = Promise<Action>;
export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any; // eslint-disable-line
export type Dispatch = (
  action: Action | ThunkAction | PromiseAction | Array<Action>
) => any;

export function fetchAnimal(token: ?string = null): ThunkAction {
  return (dispatch, getState) => {
    if (getState().animalState.token) return;

    dispatch({ type: t.GET_ANIMAL_START });
    requestManager
      .fetchAnimal(token)
      .then(value =>
        dispatch({ type: t.GET_ANIMAL_SUCCESS, animal: value.data })
      )
      .catch(error =>
        dispatch({ type: t.GET_ANIMAL_FAIL, error: error.message })
      );
  };
}

export function fetchQuestionMeta(): ThunkAction {
  return dispatch => {
    dispatch({ type: t.GET_QUESTION_META_START });
    requestManager
      .fetchQuestionMeta()
      .then(value =>
        dispatch({
          type: t.GET_QUESTION_META_SUCCESS,
          questionMeta: value.data
        })
      )
      .catch(error =>
        dispatch({ type: t.GET_QUESTION_META_FAIL, error: error.message })
      );
  };
}
