// @flow
import requestManager from "../../libs/requestManager";
import type { CardObject } from "../card/types";
import * as t from "./types";

type GetCardTypesAction = { type: typeof t.GET_CARD_TYPES_START };
type GetCardTypesFailedAction = {
  type: typeof t.GET_CARD_TYPES_FAILED,
  error: ?string
};
type GetCardTypesSuccessAction = {
  type: typeof t.GET_CARD_TYPES_SUCCESS,
  card_types: Array<t.CardType>
};
type GetCardAction = { type: typeof t.VIEW_CARD_START };
type GetCardFailedAction = { type: typeof t.VIEW_CARD_FAILED, error: ?string };
type GetCardSuccessAction = {
  type: typeof t.VIEW_CARD_SUCCESS,
  card: CardObject
};
type GetCardsAction = { type: typeof t.GET_CARDS_START };
type GetCardsFailedAction = { type: typeof t.GET_CARDS_FAILED, error: ?string };
type GetCardsSuccessAction = {
  type: typeof t.GET_CARDS_SUCCESS,
  card: Array<CardObject>,
  lastPage: number
};
type ToggleCardTypeAction = {
  type: typeof t.TOGGLE_CARD_TYPE,
  card_type: number
};
type ResetFilterAction = { type: typeof t.RESET_FILTER };

type Action =
  | GetCardTypesAction
  | GetCardTypesFailedAction
  | GetCardTypesSuccessAction
  | GetCardAction
  | GetCardFailedAction
  | GetCardSuccessAction
  | GetCardsAction
  | GetCardsFailedAction
  | GetCardsSuccessAction
  | ToggleCardTypeAction
  | ResetFilterAction;

export type GetState = () => Object;
export type PromiseAction = Promise<Action>;
export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any; // eslint-disable-line
export type Dispatch = (
  action: Action | ThunkAction | PromiseAction | Array<Action>
) => any;

export function getCardTypes(): ThunkAction {
  return dispatch => {
    dispatch({ type: t.GET_CARD_TYPES_START });
    requestManager
      .getCardTypes()
      .then(value =>
        dispatch({ type: t.GET_CARD_TYPES_SUCCESS, card_types: value.data })
      )
      .catch(error =>
        dispatch({ type: t.GET_CARD_TYPES_FAILED, error: error.message })
      );
  };
}

export function getCard(token: ?string = null, id: any): ThunkAction {
  return dispatch => {
    dispatch({ type: t.VIEW_CARD_START });
    requestManager
      .fetchCard(token, id)
      .then(res => {
        dispatch({
          type: t.GET_CARDS_SUCCESS,
          cards: [res.data.card]
        });
      })
      .catch(err => dispatch({ type: t.VIEW_CARD_FAILED, error: err }));
  };
}

export function getCards(authToken: ?string): ThunkAction {
  return (dispatch, getState) => {
    const { lastPage, pending, filter } = getState().cardState;
    const token = authToken || getState().animalState.token;

    if (pending) return;

    dispatch({ type: t.GET_CARDS_START });
    requestManager
      .fetchCards(token, lastPage + 1, filter.card_types)
      .then(res => {
        dispatch({
          type: t.GET_CARDS_SUCCESS,
          cards: res.data,
          lastPage: lastPage + 1
        });
      })
      .catch(err => dispatch({ type: t.GET_CARDS_FAILED, error: err }));
  };
}

export function toggleCardType(cardTypeId: number): ThunkAction {
  return (dispatch, getState) => {
    const { token } = getState().animalState.animal;
    Promise.all([
      dispatch({ type: t.TOGGLE_CARD_TYPE, card_type: cardTypeId }),
      dispatch(getCards(token))
    ]);
  };
}

export function resetFilter(): ThunkAction {
  return (dispatch, getState) => {
    const { token } = getState().animalState.animal;
    Promise.all([
      dispatch({ type: t.RESET_FILTER }),
      dispatch(getCards(token))
    ]);
  };
}
