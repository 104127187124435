// @flow
import * as React from 'react';
import s from './ListTitle.scss';

type Props = {
  title: string,
};

class ListTitle extends React.Component<Props> {
  render() {
    const { title } = this.props;
    return (
      <div className={s.listTitle}>
        <span className={s.title}>{title}</span>
      </div>
    );
  }
}

export default ListTitle;
