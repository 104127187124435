// @flow
import * as React from 'react';
import s from './Ration.scss';
import PartialRation from './PartialRation';
import RationTab from './RationTab';
import type { RationObject, PartialRationObject } from '../store/ration/types';

type Props = {
  title: string,
  ration: RationObject,
};

type State = {
  selectedIndex: number,
};

class Ration extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedIndex: props.ration.partial_rations.length - 1,
    };
  }

  tabNodes(ration: RationObject): { default: Array<React.Node>, summary: React.Node } {
    const { selectedIndex } = this.state;
    const nodes = ration.partial_rations.map((partial, idx) => {
      const isEnabled = partial.entries.length > 0;
      const isLast = idx === ration.partial_rations.length - 1;
      return (
        <RationTab
          key={idx}
          tabIndex={`${idx}`}
          isSelected={selectedIndex === idx}
          isEnabled={isEnabled}
          isSummary={partial.type === 'summary'}
          title={partial.name}
          onClick={() => this.setState({ selectedIndex: idx })}
          style={isLast ? { marginRight: '0px' } : undefined}
        />
      );
    });
    return {
      default: nodes.slice(0, nodes.length - 1),
      summary: nodes[nodes.length - 1],
    };
  }

  rationDescription(partialRation: PartialRationObject) {
    return (
      <div className={s.rationDescription}>
        <p>Foder</p>
        <p>{partialRation.description}</p>
      </div>
    );
  }

  render() {
    const { title, ration } = this.props;
    const { selectedIndex } = this.state;
    const tabs = this.tabNodes(ration);
    const selectedPartial = ration.partial_rations[selectedIndex];
    return (
      <div>
        <div className={s.header}>
          <p className={s.title}>{title}</p>
          <div className={s.tabs}>
            <div className={s.miscTabs}>{tabs.default}</div>
            {tabs.summary}
          </div>
          {this.rationDescription(selectedPartial)}
        </div>
        {<PartialRation partialRation={selectedPartial} />}
      </div>
    );
  }
}

export default Ration;
