// @flow
import * as C from "./types";
import type { FilterObject, CardObject, CardType } from "./types";
import type { ThunkAction } from "./actions";

export type CardState = {
  cards: Array<CardObject>,
  filter: FilterObject,
  pending: false,
  lastPage: 0,
  card_types: []
};

export const initialState: CardState = {
  cards: [],
  filter: {
    card_types: []
  },
  card_types: [],
  lastPage: 0,
  pending: false
};

const removeCardType = (state: CardState, action: ThunkAction): mixed => {
  const index = state.filter.card_types.findIndex(
    cardTypeId => parseInt(cardTypeId, 10) === parseInt(action.card_type, 10)
  );
  if (index < 0) {
    return false;
  }

  const cardTypes = [].concat(state.filter.card_types);
  cardTypes.splice(index, 1);
  return cardTypes;
};

const addCardType = (state: CardState, action: ThunkAction): Array<CardType> =>
  state.filter.card_types.concat(action.card_type);

const toggleCardType = (state, action) =>
  removeCardType(state, action) || addCardType(state, action);

const updateCard = (cards, card) => {
  const index = cards.findIndex(c => c.id === card.id);
  const newCards: Array<CardObject> = [].concat(cards);
  newCards.splice(index, 1, card);
  return newCards;
};

const updateAndSortCards = (
  oldCards: Array<CardObject>,
  newCards: Array<CardObject>
) => {
  const cardsCopy: Array<CardObject> = [].concat(oldCards);

  newCards.forEach(card => {
    const index = cardsCopy.findIndex(c => card.id === c.id);
    if (index > -1) {
      cardsCopy.splice(index, 1, card);
    } else {
      cardsCopy.push(card);
    }
  });
  cardsCopy.concat(newCards);
  cardsCopy.sort((a, b) =>
    new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
  );
  return cardsCopy;
};

export const cardReducer = (
  state: CardState = initialState,
  action: ThunkAction
) => {
  switch (action.type) {
    case C.VIEW_CARD_SUCCESS:
      return {
        ...state,
        cards: updateCard(state.cards, action.card),
        pending: false
      };

    case C.GET_CARDS_SUCCESS:
      return {
        ...state,
        cards: updateAndSortCards(state.cards, action.cards),
        lastPage: action.lastPage,
        pending: false
      };
    case C.SET_CARD_TYPE:
      return {
        ...state,
        filter: { card_types: addCardType(state, action) },
        cards: [],
        lastPage: 0
      };

    case C.REMOVE_CARD_TYPE:
      return {
        ...state,
        filter: { card_types: removeCardType(state, action) },
        cards: [],
        lastPage: 0
      };

    case C.TOGGLE_CARD_TYPE:
      return {
        ...state,
        filter: { card_types: toggleCardType(state, action) },
        cards: [],
        lastPage: 0
      };

    case C.GET_CARD_TYPES_SUCCESS:
      return {
        ...state,
        card_types: action.card_types
      };

    case C.VIEW_CARD_START:
    case C.GET_CARDS_START:
      return {
        ...state,
        pending: true
      };

    case C.VIEW_CARD_FAILED:
    case C.GET_CARDS_FAILED:
      return {
        ...state,
        pending: false,
        error: action.error
      };

    case C.RESET_FILTER:
      return {
        ...state,
        filter: { card_types: [] },
        lastPage: 0
      };

    default:
      return state;
  }
};
