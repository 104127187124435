// @flow
import * as t from './types';
import * as cardTypes from '../card/types';
import * as a from './actions';
import type { QuestionMetaObject } from '../card/types';

export type AnimalState = {
  animal: ?t.AnimalObject,
  questionMeta: Array<QuestionMetaObject>,
};

export const initialState: AnimalState = {
  animal: {
    name: '',
    id: undefined,
    contacts: [],
    normal_values_average: {
      temperature: null,
      resting_heart_rate: null,
      respiratory_rate: null,
    },
    user: {
      first_name: '',
      last_name: '',
    },
  },
  created_at: '',
  questionMeta: [],
};

export const animalReducer = (
  state: AnimalState = initialState,
  action: a.ThunkAction,
) => {
  switch (action.type) {
    case cardTypes.VIEW_CARD_SUCCESS:
      return {
        ...state,
        animal: {
          ...action.animal,
        },
      };
    case t.GET_ANIMAL_SUCCESS:
      return {
        ...state,
        pending: false,
        animal: {
          ...action.animal,
          normal_values_average: {
            respiratory_rate:
              Math.round(action.animal.normal_values_average.respiratory_rate *
                  100) / 100,
            resting_heart_rate:
              Math.round(action.animal.normal_values_average.resting_heart_rate *
                  100) / 100,
            temperature:
              Math.round(action.animal.normal_values_average.temperature * 100) / 100,
          },
        },
        token: action.animal.token,
        created_at: action.animal.created_at,
      };

    case t.GET_QUESTION_META_SUCCESS:
      return { ...state, questionMeta: action.questionMeta, pending: false };

    case t.GET_ANIMAL_START:
    case t.GET_QUESTION_META_START:
      return { ...state, pending: true };

    case t.GET_ANIMAL_FAIL:
    case t.GET_QUESTION_META_FAIL:
      return { ...state, pending: false };
    default:
      return state;
  }
};
