// @flow
import * as React from 'react';
import s from './RatingListEntry.scss';

type Props = {
  name: string,
  value: number,
};

const ratingNodes = (value: number) => {
  const nodes = [];
  for (let i = 0; i < 5; i += 1) {
    nodes.push(<i
      key={i}
      className={`${s.rating} icon ion-heart ${
          value > i ? s.active : s.inactive
        }`}
    />);
  }
  return nodes;
};

class RatingListEntry extends React.Component<Props> {
  render() {
    const { name, value } = this.props;

    return (
      <div className={s.listEntry}>
        <span className={s.name}>{name}</span>
        <div className={s.spacer} />
        <div className={s.wrapper}>{ratingNodes(value)}</div>
        <span className={s.value}>{`${value || 0} / 5`}</span>
      </div>
    );
  }
}

export default RatingListEntry;
