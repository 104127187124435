// @flow
export type UserObject = {
  id?: number,
  first_name?: string,
  last_name?: string,
  email?: ?string,
  phone?: ?string,
  merits?: ?string,
  address?: ?string,
  zipcode?: ?string,
  city?: ?string,
  other_merits?: ?string
};

export const GET_SAMPLES_START = "@@user/GET_SAMPLES_START";
export const GET_SAMPLES_SUCCESS = "@@user/GET_SAMPLES_SUCCESS";
export const GET_SAMPLES_FAIL = "@@user/GET_SAMPLES_FAIL";
export const CONFIRM_EMAIL_SUCCESS = "@@user/CONFIRM_EMAIL_SUCCESS";
export const CONFIRM_EMAIL_FAIL = "@@user/CONFIRM_EMAIL_FAIL";
export const CONFIRM_EMAIL_START = "@@user/CONFIRM_EMAIL_START";
