import * as t from "./types";
import { Dispatch } from "redux";
import requestManager from "../../libs/requestManager";

export const fetchSample = (token: string, mocked: boolean = false) => (
  dispatch: Dispatch
) => {
  dispatch({ type: t.GET_SAMPLES_START });
  return requestManager
    .fetchSample(token, mocked)
    .then((value: any) =>
      dispatch({ type: t.GET_SAMPLES_SUCCESS, data: value.data })
    )
    .catch((error: any) =>
      dispatch({ type: t.GET_SAMPLES_FAIL, error: error.message })
    );
};

export const confirmEmail = (token: string) => (dispatch: Dispatch) => {
  dispatch({ type: t.CONFIRM_EMAIL_START });
  return requestManager
    .confirmEmail(token)
    .then(() => dispatch({ type: t.CONFIRM_EMAIL_SUCCESS }))
    .catch((error: any) =>
      dispatch({ type: t.CONFIRM_EMAIL_FAIL, error: error.response.data.error })
    );
};
