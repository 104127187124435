import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";

import {
  animalReducer,
  initialState as animalState
} from "./animal/animalReducer";
import { cardReducer, initialState as cardState } from "./card/cardReducer";
import { appReducer, initialState as appState } from "./app/appReducer";
import { userReducer, initialState as userState } from "./user/userReducer";

const history = createBrowserHistory();

export const initialState = {
  animalState,
  cardState,
  appState,
  userState
};

export default {
  animalState: animalReducer,
  cardState: cardReducer,
  appState: appReducer,
  userState: userReducer,
  router: connectRouter(history)
};
